import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import {
	SettingsCard,
	SettingsCardTitle,
} from '@/features/settings/components/card'
import { TenantSettingsSelect } from '@/features/tenant/components/tenant-settings-select'

import QueryBoundary from '@/components/common/query-boundary'
import TableSkeleton from '@/components/skeletons/table-skeleton'

const NotificationsSettings = lazy(
	() => import('@/features/notifications/components/settings'),
)

export const Route = createFileRoute('/_dashboard/settings/notifications')({
	component: NotificationsRoute,
})

function NotificationsRoute() {
	return (
		<>
			<div className="flex flex-col items-start justify-start gap-2">
				<p className="text-muted-foreground">Notifications for:</p>
				<TenantSettingsSelect />
			</div>
			<SettingsCard className="flex-col items-start sm:flex-row">
				<SettingsCardTitle>Notifications</SettingsCardTitle>

				<QueryBoundary fallback={() => <div>Error...</div>}>
					<Suspense fallback={<TableSkeleton rows={5} />}>
						<NotificationsSettings />
					</Suspense>
				</QueryBoundary>
			</SettingsCard>
		</>
	)
}
