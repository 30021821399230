import { api } from '@/lib/api'

import type {
	AvailableIntegration,
	Integration,
	IntegrationDetails,
	IntegrationDetailsDTO,
	IntegrationDTO,
	NewIntegrationFormData,
	ValidateIntegrationFormData,
} from './types'

/**
 * Fetch available integrations for the current tenant
 */
export const fetchAvailableIntegrations = async (tenant: string) => {
	if (!tenant) {
		return []
	}

	const { data } = await api.get<AvailableIntegration[]>(
		`/api/tenants/${tenant}/available_integrations`,
	)

	return data
}

/**
 * Fetch connected integrations for the current tenant
 */
export const fetchTenantIntegrations = async (tenant: string) => {
	if (!tenant) {
		return []
	}

	const {
		data: { connectors },
	} = await api.get<IntegrationDTO>(`/api/tenants/${tenant}/connectors`)

	return connectors
}

const EMPTY_INTEGRATION = {
	default: false,
	definition_id: '',
	enabled: false,
	events_enabled: false,
	events_options: null,
	id: '',
	last_successful_connection: '',
	last_updated: '',
	metrics_enabled: false,
	metrics_options: null,
	name: '',
	state: null,
	tenant: '',
}

/**
 * Fetch details for a specific integration
 */
export const fetchIntegrationDetails = async (
	tenant: string,
	integration_id: string,
): Promise<Integration> => {
	if (!tenant || !integration_id) {
		return EMPTY_INTEGRATION
	}

	const {
		data: { connector },
	} = await api.get<IntegrationDetailsDTO>(
		`/api/tenants/${tenant}/connectors/${integration_id}`,
	)

	return connector
}

const EMPTY_INTEGRATION_DETAILS: IntegrationDetails = {
	vendor: '',
	product: '',
	version: '',
	visible: false,
	name: '',
	description: '',
	can_fetch_events: false,
	event_options_schema: {
		title: '',
		description: '',
		type: 'object',
	},
	can_fetch_metrics: false,
	metric_options_schema: {
		title: '',
		description: '',
		type: 'object',
	},
	can_test_connection: false,
}

/**
 * Fetch connected integrations for the current tenant
 */
export const fetchIntegrationDefinition = async (
	tenant: string,
	integration_id: string,
): Promise<IntegrationDetails> => {
	if (!tenant || !integration_id) {
		return EMPTY_INTEGRATION_DETAILS
	}

	const { data } = await api.get<IntegrationDetails>(
		`/api/tenants/${tenant}/available_integrations/${integration_id}`,
	)

	return data
}

/**
 * Create a new Integration
 * @param tenant - The tenant ID
 * @param integration_data - The integration data
 */
export const createIntegration = async (
	tenant: string,
	integration_data: NewIntegrationFormData,
) => {
	const {
		data: { connector },
	} = await api.put<IntegrationDetailsDTO>(
		`/api/tenants/${tenant}/connectors`,
		integration_data,
	)

	return connector
}

/**
 * Edit an Integration
 * @param tenant - The tenant ID
 * @param integration_id - The integration ID
 * @param integration_data - The integration data
 */
export const editIntegration = async (
	tenant: string,
	integration_id: string,
	integration_data: NewIntegrationFormData,
) => {
	const { data } = await api.patch<Integration>(
		`/api/tenants/${tenant}/connectors/${integration_id}`,
		integration_data,
	)

	return data
}

/**
 * Delete an Integration
 * @param tenant - The tenant ID
 * @param integration_id - The integration ID
 */
export const deleteIntegration = async (
	tenant: string,
	integration_id: string,
) => {
	const { data } = await api.delete<Integration>(
		`/api/tenants/${tenant}/connectors/${integration_id}`,
	)

	return data
}

/**
 * Validate an Integration
 * @param tenant - The tenant ID
 * @param integration_data - The integration data
 */
export const validateIntegration = async (
	tenant: string,
	integration_data: NewIntegrationFormData,
) => {
	const { data } = await api.post<ValidateIntegrationFormData>(
		`/api/tenants/${tenant}/validate_connector_configuration`,
		integration_data,
	)

	return data
}
