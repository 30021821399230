import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { parseRedirect } from '@/utils/routing'
import { z } from 'zod'

import { AuthLayout } from '@/features/auth/components/layout'
import { isUserAuthenticated } from '@/features/auth/queries'

/**
 * Auth layout that wraps the entire authentication flow.
 */

const searchSchema = z.object({
	redirect: z.string().optional(),
})

export const Route = createFileRoute('/_auth')({
	/**
	 * We check the user auth session before loading the auth layout.
	 * @see https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes
	 */
	validateSearch: zodSearchValidator(searchSchema),

	beforeLoad: async ({ search, context: { queryClient }, location }) => {
		// If the user is logging out, don't check if they're authenticated
		if (location.pathname === '/logout') return

		// If user is not authenticated, ignore redirect
		const isAuthenticated = await isUserAuthenticated(queryClient)
		if (!isAuthenticated) return

		// Grab the redirect URL from the search
		const { redirect: redirectURL } = search

		// If there's a redirect URL, redirect the user
		if (redirectURL) {
			const { pathname, search: parsedSearchObject } =
				parseRedirect(redirectURL)

			// Redirect the user to the page they were trying to access
			throw redirect({
				to: pathname,
				search: { ...parsedSearchObject },
			})
		} else {
			// If there's no redirect URL, redirect the user to the home page
			throw redirect({
				to: '/graylog',
			})
		}
	},

	component: AuthRoute,
})

function AuthRoute() {
	return (
		<AuthLayout>
			<Outlet />
		</AuthLayout>
	)
}
