import { useEffect } from 'react'

import { Link, useNavigate } from '@tanstack/react-router'

import { cn } from '@/utils'
import { Bell, ChevronsUpDown, LogOut, Settings } from 'lucide-react'

import { sentry } from '@/lib/sentry'
import { identifyUser } from '@/lib/umami'

import NotificationsCounter from '@/features/notifications/components/counter'
import { useNotificationsPanel } from '@/features/notifications/hooks/useNotificationsPanel'
import { useMe } from '@/features/user/queries'

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from '@/components/ui/sidebar'

import ThemeToggle from '../common/theme-toggle'

import { UserAvatar } from './user-avatar'

export function UserButton() {
	const {
		data: { user, avatar_url, user_id },
	} = useMe()

	const { open: openNotificationsPanel } = useNotificationsPanel()

	const { isMobile, open } = useSidebar()
	const navigate = useNavigate()

	// Set the Sentry user
	useEffect(() => {
		if (user_id) {
			sentry.setUser({
				id: user_id.toString(),
			})

			identifyUser(user_id.toString())
		}
	}, [user_id])

	const handleLogout = () => {
		navigate({
			to: '/logout',
			search: { redirect: encodeURIComponent(window.location.href) },
		})
	}

	return (
		<SidebarMenu>
			<SidebarMenuItem>
				<NotificationsCounter
					className={cn({
						'left-7 top-0': open,
						'-top-2 left-5': !open,
					})}
				/>

				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<SidebarMenuButton
							size="lg"
							className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
						>
							<UserAvatar user={user} avatar={avatar_url ?? ''} />
							<div className="grid flex-1 text-left text-sm leading-tight">
								<span className="truncate font-semibold">
									{user.fname} {user.lname}
								</span>
								<span className="truncate text-xs">{user.uname}</span>
							</div>
							<ChevronsUpDown className="ml-auto size-4" />
						</SidebarMenuButton>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
						side={isMobile ? 'bottom' : 'right'}
						align="end"
						sideOffset={4}
					>
						<DropdownMenuLabel className="p-0 font-normal">
							<div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
								<UserAvatar user={user} avatar={avatar_url ?? ''} />
								<div className="grid flex-1 text-left text-sm leading-tight">
									<span className="truncate font-semibold">
										{user.fname} {user.lname}
									</span>
									<span className="truncate text-xs">{user.uname}</span>
								</div>
							</div>
						</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<ThemeToggle isMenu />
						<DropdownMenuSeparator />
						<DropdownMenuGroup>
							<DropdownMenuItem asChild>
								<Link to="/settings" className="cursor-pointer">
									<Settings className="mr-2 h-6 w-6" />
									Settings
								</Link>
							</DropdownMenuItem>
						</DropdownMenuGroup>
						<DropdownMenuGroup>
							<DropdownMenuItem onClick={openNotificationsPanel}>
								<NotificationsCounter className="-top-1 left-5" />
								<Bell className="mr-2 h-6 w-6" />
								Notifications
							</DropdownMenuItem>
						</DropdownMenuGroup>
						<DropdownMenuSeparator />
						<DropdownMenuItem onClick={handleLogout} className="cursor-pointer">
							<LogOut className="mr-2 h-6 w-6" />
							Log out
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</SidebarMenuItem>
		</SidebarMenu>
	)
}
