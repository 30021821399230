import { useCurrentRole } from '@/features/user/store'

import { hasRequiredRole } from '../helpers'

import type { Roles } from '../types'
import type { PropsWithChildren } from 'react'

/**
 * Component that renders its children only if the user has the required role
 */
export const HasRole = ({
	children,
	role,
	fallback,
}: PropsWithChildren<{ role: Roles; fallback?: React.ReactNode }>) => {
	const userRole = useCurrentRole() as Roles

	if (!hasRequiredRole(userRole, role)) {
		return fallback ?? null
	}

	return <>{children}</>
}
