import { useEffect } from 'react'

import { env } from '@/env'

import { getUserTimeZone } from './date'

// Define types for Umami tracking
export type UmamiEvent = {
	type: string
	value?: string | number
	url?: string
	referrer?: string
}

declare global {
	interface Window {
		umami?: {
			track: (event: string, data?: any) => void
			trackEvent: (event: string, data?: any) => void
			trackView: (url?: string) => void
			identify: (data: any) => void
		}
	}
}

export const useUmami = () => {
	useEffect(() => {
		// Create and configure Umami script
		const script = document.createElement('script')
		script.defer = true
		script.async = true
		script.src = 'https://umami.dev.bitlyftsecurity.com/script.js'
		script.setAttribute('data-website-id', env.VITE_UMAMI_WEBSITE_ID)
		script.setAttribute('data-auto-track', 'true')
		script.setAttribute('data-do-not-track', 'false')
		script.setAttribute('data-cache', 'true')

		document.head.appendChild(script)

		return () => {
			document.head.removeChild(script)
		}
	}, [])
}

// Utility functions for tracking specific metrics
export const trackFeatureUsage = (featureName: string, action: string) => {
	window.umami?.trackEvent('feature_usage', {
		feature: featureName,
		action: action,
		timestamp: new Date().toISOString(),
	})
}

export const trackUserJourney = (
	step: string,
	details?: Record<string, any>,
) => {
	window.umami?.trackEvent('user_journey', {
		step,
		details,
		timestamp: new Date().toISOString(),
	})
}

export const trackError = (errorType: string, errorDetails: string) => {
	window.umami?.trackEvent('error', {
		type: errorType,
		details: errorDetails,
		timestamp: new Date().toISOString(),
	})
}

export const trackPageView = (url?: string) => {
	window.umami?.trackView(url)
}

export const identifyUser = (userId: string) => {
	window.umami?.identify({
		id: userId,
		timezone: getUserTimeZone(),
	})
}

/**
 * Track an event with Umami
 */
export const trackEvent = (event: string, data?: any) => {
	window.umami?.track(event, data)
}
